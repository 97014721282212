.accessManagement-container {
    padding: 0px 0px 30px;
}
.app-table {
    .MuiTableCell-head {
        color: $black;
    }
    .MuiTableCell-stickyHeader {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }
    .MuiTableCell-head {
        line-height: normal;
    }

    .user-email {
        // font-size: 14px !important;
        color: #191e38 !important;
    }
    .user-Role {
        color: #191e38 !important;
        font-weight: 600;
    }
    .edit-icon-style {
        background-color: #eeeff2;
        padding: 8px;
        border-radius: 3px;
        cursor: pointer;
    }
    .auto-renew-checkbox1 {
        .MuiCheckbox-colorPrimary.Mui-disabled {
            color: none !important;
        }
    }
}
.pagination-loader {
    display: flex;
    justify-content: center;
    align-items: center;
}
.MuiTablePagination-root .MuiTablePagination-actions {
    margin-right: 20px !important;
}
.search-box-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiFormControl-root {
        width: 600px !important;
    }
}
